import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

class TopicPageTemplate extends React.Component {
  render() {
    const props = this.props
    const topic = this.props.pageContext.topic
    const posts = this.props.data.allMarkdownRemark.edges
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title={`#${topic.charAt(0).toUpperCase() + topic.slice(1)}`}
          keywords={[`${topic}`, `blog`]}
        />
        <header className="topic-page-head">
          <h1 className="page-head-title">#{topic}({props.data.allMarkdownRemark.totalCount})</h1>
        </header>

        <div className="post-feed">
          {posts.map(({ node }) => {
            return (
              <PostCard
                key={node.fields.slug}
                node={node}
                postClass={`post`}
              />
            )
          })}
        </div>
    </Layout>
    )
  }
}

export default TopicPageTemplate

export const pageQuery = graphql`
  query PostByTopic($topic: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    allMarkdownRemark(filter: { frontmatter: { topics: { in: [$topic] } } }, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            topics
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1600) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`